
import boltbuzz from '../newimages/boltz_buzz_logo.png'


const Adver =()=>{


   const Tsytle={display:'flex', padding:'30px', justifyContent:'space-between', flexDirection:'column', alignItems:'center'}
    const Pstyle={
        color:'black',
        fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'40px'
    }
    return (
        <div style={{textAlign:'center',display:'flex',marginBottom:'80px', flexWrap:'wrap'}}>
        <img src={boltbuzz} style={window.innerWidth>600 ? {width:'50%', maxWidth:'50%', height:'auto'}: {width:'100%',height:'auto'}} alt="Boltz Buzz"/>
        <div style={window.innerWidth>600 ? {...Tsytle,maxWidth:'50%'}:{...Tsytle,maxWidth:'100%'}} >
          <p style={Pstyle}>Boltz Buzz is your monthly dose of insights into the latest happenings of drug discovery world 
          </p>
          <button  className="Buttn" onClick={()=>{window.location.href ='https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7119930896895406080'}}>
            SUBSCRIBE NOW
          </button>
          <p style={Pstyle}>
            Get the latest updates on our newest innovations
          </p>
        </div>
    </div>
    )
    
}

export default Adver