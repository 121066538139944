import { useEffect, useState } from "react";
import "../css/Blog.css";
import { db } from "../App";
import { Navigate, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { post } from "jquery";
import { Button } from "bootstrap";
import Adver from "./Adver";

import { Helmet } from "react-helmet-async";

const BLOG = () => {
  const Nav = useNavigate();

  const [Pdata, setPdata] = useState([]);
  const [Search, setSearch] = useState("");
  const [Reference, SetReference] = useState([]);
  const [reslmessage, setreslmessage] = useState("");
  const [Postnotfound, setPostnotfound] = useState(false);

  const FETCHPosts = async () => {
    const Ref = collection(db, "boltzmannlabs-posts");

    await onSnapshot(Ref, (POSTE) => {
      if (POSTE.empty) {
        setPostnotfound(true);
      } else {
        const postarray = [];
        POSTE.forEach((Posts) => {
          if (Posts.data().published) {
            postarray.push({
              id: Posts.id,
              Author: Posts.data().author,
              category: Posts.data().categories,
              title: Posts.data().title,
              content: Posts.data().content.match(/<img[^>]*>/),
              date: Posts.data().created_date.toDate(),
            });
          }
        });
        if (postarray.length == 0) {
          setPostnotfound(true);
        }

        postarray.sort(function (a, b) {
          return b.date - a.date;
        });
        setPdata(postarray);
        SetReference(postarray);
      }
    });
  };

  const Cat = [
    "Technology",
    "Case Studies",
    "Education",
    "Collabration",
    "Industrial Insights",
  ];

  const SearchFun = async () => {
    const data = Reference;
    const Farray = await data.filter((data) => {
      return data.title.toLowerCase().includes(Search);
    });
    if (Farray.length == 0) {
      setPostnotfound(true);
    }
    setPdata(Farray);

    setreslmessage(Search);
  };
  const clearSearch = () => {
    setSearch("");
    setPdata(Reference);
    setreslmessage("");
  };

  console.log(window.innerWidth);

  useEffect(() => {
    setTimeout(() => {
      FETCHPosts();
    }, 200);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ marginTop: "20px" }}>
      <Helmet>
        <title>Boltzmann | Blog</title>
        <meta
          porperty="description"
          content="The Boltzmann Blog is a unique resource, offering comprehensive and versatile information on advancements in AI in drug discovery."
        />
        <meta
          property="og:description"
          content="The Boltzmann Blog is a unique resource, offering comprehensive and versatile information on advancements in AI in drug discovery."
        />
        <meta property="og:title" content="Boltzmann-Blog" />
        <meta property="og:url" content="https://boltzmann.co/blog" />
        <meta
          property="twitter:description"
          content="The Boltzmann Blog is a unique resource, offering comprehensive and versatile information on advancements in AI in drug discovery."
        />
        <meta property="twitter:title" content="Boltzmann-Blog" />
        <meta property="twitter:url" content="https://boltzmann.co/blog" />
      </Helmet>

      <section class="blog_area sec_pad" style={{ padding: "80px 0px" }}>
        <div class="container">
          <Adver />
          <div
            class="widget sidebar_widget widget_search"
            style={{ marginBottom: "100px" }}
          >
            <div
              className="searchbar"
              style={{
                borderStyle: "solid",
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "10px",
                borderColor: "#501f84",
              }}
            >
              <input
                style={{
                  border: "none",
                  background: "none",
                  outline: "none",
                  width: "90%",
                  paddingLeft: "10px",
                }}
                placeholder="Enter key words"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    SearchFun();
                  }
                }}
                onChange={(e) => {
                  setSearch(e.target.value.toLowerCase());
                }}
                value={Search}
              ></input>
              <button
                style={
                  Search == ""
                    ? { display: "none" }
                    : { border: "none", background: "none" }
                }
                onClick={clearSearch}
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
              <button
                style={{ border: "none", background: "none" }}
                onClick={SearchFun}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <div
            class="row"
            style={
              window.innerWidth < 600
                ? {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }
            }
          >
            <div
              class="col-lg-4"
              style={
                window.innerWidth > 600
                  ? { maxWidth: "20%", flex: "0 0 20%" }
                  : { maxWidth: "100%", flex: "0 0 100%" }
              }
            >
              <div
                class="blog-sidebar"
                style={{ paddingLeft: "10px", marginBottom: "150px" }}
              >
                <div
                  class="widget sidebar_widget widget_recent_post mt_60"
                  style={{ marginTop: "0px" }}
                >
                  <div class="widget_title">
                    <h3
                      class="f_p f_size_20 t_color3"
                      style={{
                        color: "black",
                        textAlign: "left",
                        fontSize: "22px",
                      }}
                    >
                      Categories
                    </h3>
                    <div
                      class="border_bottom"
                      style={{ maxWidth: "80%" }}
                    ></div>
                  </div>
                  <div style={{ maxHeight: "600px" }}>
                    <ul class="list-unstyled">
                      {Cat.map((data, index) => {
                        return (
                          <li key={index}>
                            {" "}
                            <button
                              style={{ background: "none", border: "none" }}
                              onClick={(e) => {
                                e.preventDefault();
                                Nav(`/blog/${data}`);
                              }}
                            >
                              <span>{data}</span>
                            </button>{" "}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-8"
              style={
                window.innerWidth > 600
                  ? { maxWidth: "100%", flex: "0 0 80%" }
                  : { maxWidth: "100%", flex: "0 0 100%" }
              }
            >
              {reslmessage != "" && (
                <div style={{ marginBottom: "10px" }}>
                  <span
                    style={{ color: "#501f84", fontWeight: 600, left: "25%" }}
                  >
                    Search results for {reslmessage}
                  </span>
                  <button
                    onClick={clearSearch}
                    style={{ background: "none", border: "none" }}
                  >
                    <i class="fa fa-times" aria-hidden="true" />
                  </button>
                </div>
              )}
              {Pdata.length == 0 ? (
                Postnotfound ? (
                  <p
                    style={{ fontSize: "20px", color: "red", padding: "10px" }}
                  >
                    {" "}
                    No posts Found
                  </p>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      minheigh: "50vh",
                      textAlign: "center",
                    }}
                  >
                    <span
                      class="loader"
                      style={{ position: "relative", top: "50%" }}
                    ></span>
                  </div>
                )
              ) : (
                <div class="row" id="blog_masonry">
                  {Pdata.map((data, index) => {
                    return (
                      <div
                        class="col-lg-6 col-sm-6"
                        style={
                          window.innerWidth < 600
                            ? { maxWidth: "100%" }
                            : {
                                maxWidth: "32%",
                                justifyContent: "space-between",
                                marginBottom: "30px",
                              }
                        }
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          Nav(`/post/${data.id}`);
                        }}
                      >
                        <div
                          class="blog_grid_item mb-30"
                          style={
                            window.innerWidth > 600
                              ? {
                                  maxHeight: "100%",
                                  minHeight: "100%",
                                  overflow: "hidden",
                                  boxShadow:
                                    "0px 2px 4px 0px rgba(12, 0, 46, 0.06)",
                                  border: "solid",
                                  borderRadius: "10px",
                                  borderWidth: "thin",
                                }
                              : {
                                  maxHeight: "100%",
                                  boxShadow:
                                    "0px 2px 4px 0px rgba(12, 0, 46, 0.06)",
                                }
                          }
                        >
                          <div
                            class="blog_img"
                            style={{ padding: "10px", background: "none" }}
                          >
                            {data.content != null ? (
                              <img
                                style={
                                  window.innerWidth > 600
                                    ? {
                                        width: "300px",
                                        height: "150px",
                                        textAlign: "center",
                                        border: "solid",
                                        borderRadius: "10px",
                                        borderWidth: "0.05px",
                                      }
                                    : {
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "center",
                                      }
                                }
                                src={
                                  data.content[0].match(
                                    /src=["']([^"']*)["']/
                                  )[1]
                                }
                                alt="Image"
                              />
                            ) : (
                              <img
                                style={{ width: "400px", height: "150px" }}
                                src={require("../newimages/blank.jpg")}
                                alt="No image"
                              />
                            )}
                          </div>
                          <div
                            class="blog_content"
                            style={{ padding: "10px", maxWidth: "90%" }}
                          >
                            {/* <div><p>{data.category}</p></div> */}

                            <a>
                              <h5
                                class="f_p f_size_20 f_500 t_color mb_20"
                                style={{
                                  fontSize: "17px",
                                  fontWeight: "bold",
                                  height: "4.6rem",
                                }}
                              >
                                <span className="blogtitle">{data.title}</span>
                              </h5>
                            </a>
                            <div
                              class="entry_post_info"
                              style={{
                                fontsize: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "12px",
                                  overflow: "clip",
                                  textWrap: "nowrap",
                                  maxWidth: "50%",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {" "}
                                {data.Author != null ? data.Author : "Admin"}
                              </span>
                              <span style={{ fontSize: "12px" }}>
                                {" "}
                                {data.date.toString().slice(4, 15)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <ul class="col-lg-12 list-unstyled page-numbers shop_page_number text-left mt_30">
                                <li><span aria-current="page" class="page-numbers current">1</span></li>
                                <li><a class="page-numbers" href="#">2</a></li>
                                <li><a class="next page-numbers" href="#"><i class="ti-arrow-right"></i></a></li>
                            </ul> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default BLOG;
